<template>
    <div class="app-layout" :class="{ 'app-layout--mobile': $vuetify.breakpoint.smAndDown }">
        <div class="app-layout__header" ref="header">
            <slot name="header" />
        </div>

        <div class="app-layout__main">
            <div class="app-layout__sidebar" :style="sidebarComputedStyle">
                <slot name="sidebar" v-bind="{ style: sidebarComputedStyle }" />
            </div>

            <div class="app-layout__page" :style="{ ...computedStyle, ...computedPageStyle }">
                <div class="app-layout__content">
                    <slot name="content" v-bind="{ style: computedStyle }" />
                </div>
            </div>

            <div class="app-layout__rightbar" :style="computedStyle" ref="rightBar">
                <slot name="rightbar" v-bind="{ style: computedStyle }"/>
            </div>

			<div class="app-layout__footer" :style="{ ...computedLeftStyle }" ref="footer">
				<slot name="footer" />
			</div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		drawerToggleStatus: { type: Boolean, default: true },
	},

    data() {
        return {
            footerHeight: 0,
            headerHeight: 0,
            rightBarWidth: 0,
        }
    },

    mounted() {
        this.updateHeights()
        window.addEventListener('resize', this.updateHeights)
        this.createResizeObserver()
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.updateHeights)
        this.destroyResizeObserver()
    },

    computed: {
        sidebarComputedStyle() {
            return {
                'padding-top': `${this.headerHeight}px`,
            }
        },
        computedStyle() {
            return {
                'padding-top': `${this.headerHeight}px`,
                'padding-bottom': `${this.footerHeight}px`
            }
        },
		computedLeftStyle() {
			if (this.$vuetify.breakpoint.smAndDown) {
				return {
					left: 0,
				}
			}

			const leftDrawerWidth = this.drawerToggleStatus ? 300 : 56;

            return {
                left: `${leftDrawerWidth}px`,
            }
		},
        computedPageStyle() {
            if (this.$vuetify.breakpoint.smAndDown) { return {} }

            return {
                width: `calc(100% - ${this.rightBarWidth}px)`
            }
        }
    },

    methods: {
        updateHeights() {
            if (!this.$refs.header || !this.$refs.footer || !this.$refs.rightBar ) { return }

            this.headerHeight = this.$refs.header.offsetHeight
            this.footerHeight = this.$refs.footer.offsetHeight
            this.rightBarWidth = this.$refs.rightBar.offsetWidth 
            setTimeout(() => {
                if (!this.$refs.header || !this.$refs.footer || !this.$refs.rightBar ) { return }

                // Timeout here to wait for vuetify breakpoints update.
                this.headerHeight = this.$refs.header.offsetHeight
                this.footerHeight = this.$refs.footer.offsetHeight
                this.rightBarWidth = this.$refs.rightBar.offsetWidth
            }, 500)
        },

        createResizeObserver() {
            if (!window.ResizeObserver) { return } // Skip if it's IE11

            this.observer = new ResizeObserver(() => { this.updateHeights() })

            const domElements = [this.$refs.header, this.$refs.footer, this.$refs.rightBar]

            domElements.forEach((target) => {
                this.observer.observe(target)
            })
        },

        destroyResizeObserver() {
            if (this.observer) { this.observer.disconnect() }
        }
    }
}
</script>

<style lang="scss">
    .app-layout {
        display: flex;
        flex-direction: column;
        height: 100vh;

        &__header, &__footer {
            position: fixed;
        }

		&__header {
            z-index: 10;
			width: 100%;
		}

        &__footer {
            z-index: 1;
            bottom: 0;
			right: 0;
        }

        &__main {
            display: flex;
            flex: 1;
        }

        &__sidebar, &__rightbar {
            height: 100%;

            & > * {
                height: 100%;
            }
        }

        &__rightbar {
            display: flex;
            justify-content: flex-end;
            right: 0;
        }

        &__page {
            width: 100%;
            display: flex;
        }

        &__content {
            flex: 1 auto;
            max-width: 100%;
        }

        &--mobile {
            .app-layout {
                &__sidebar, &__rightbar {
                    position: fixed;
                    z-index: 9;
                }

                &__rightbar {
                    right: 0;
                }
            }
        }

        // Vuetify overrides
        .v-menu {
            z-index: 12 !important;
        }
    }
</style>