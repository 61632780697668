<template>
    <div class="q-footer-details">
		<div class="q-footer-details__wrapper">
			<span>Powered by <span class="q-footer-details--spacing">QUILITY</span></span>
			<span class="q-footer-details--mini">
				<span>
					<span>Quility&copy;</span>
					<span class="pl-1">2021 - {{ currentYear }}</span>
				</span>
				<span class="mx-1">|</span>
				<span>
					<span>{{ release.Version }}{{ release.MinorVersion }}</span> -
					<router-link to="/page/hq-release-notes">Release Notes</router-link>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
import ReleaseMeta from '@/ReleaseMeta.json'
import QFooterSlider from "@/components/utils/QFooterSlider.vue";

const currentYear = new Date().getFullYear();

export default {
    components: {
        QFooterSlider
    },
    data() {
        return {
            release: ReleaseMeta,
            currentYear,
        }
    },
}
</script>

<style lang="scss">
	.q-footer-details {
		position: absolute;
		bottom: 0;
		height: 70px;
		width: 100%;
		padding: 1rem 0;
		background-color: var(--app-color-neutral-4);
		color: var(--app-color-neutral-1);
		font-size: 0.8125rem;
		line-height: 1.25rem;
		white-space: nowrap;
		display: flex;

		&__wrapper {
			display: inline-flex;
			flex-direction: column;
		}

		&--spacing {
			letter-spacing: 0.1rem;
			font-weight: 500;
		}

		&--mini {
			font-size: 0.625rem;
			color: var(--app-color-secondary);
		}
	}
</style>
