const routes = [{
        path: '/new_business/quote_tools/',
        name: 'Quote Tools',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-PolicyFinder.vue')
    },
    {
        path: '/new_business/agent_guide/',
        name: 'Agent Guide',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/ProdLinks/AgentGuide.vue')
    },
    {
        path: '/new_business/run_quote/',
        name: 'Run Quote',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/ProdLinks/RunQuote.vue')
    },
    {
        path: '/new_business/application/',
        name: 'Application',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/ProdLinks/Application.vue')
    },
    {
        path: '/new_business/pending_policy/',
        name: 'Pending Policy',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/PendingPolicy/MyPendingPolicies.vue')
    },
    {
        path: '/new_business/baseshop_pending_policy/',
        name: 'Baseshop Pending Policy',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/PendingPolicy/BaseShopPendingPolicies.vue')
    },
    {
        path: '/new_business/commission/',
        name: 'Commission',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Comission/AgentComission.vue')
    },
    {
        path: '/new_business/financial_information_form/',
        name: 'FIF Form',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-FIF.vue')
    },

    {
        path: '/new_business/workflow/',
        name: 'Workflow',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow.vue'),
        children: [{
                name: "Needs Analysis",
                path: ':lead_id?/needs-analysis',
                component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-NeedsAnalysis.vue'),
            },
            {
                name: "Policy Finder",
                path: ':lead_id?/policy-finder/',
                component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-PolicyFinder_dev.vue'),
            },
            {
                name: "Quoting",
                path: ':lead_id?/quoting/',
                component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-Quoting.vue'),
            },
            {
                name: "Workflow Application",
                path: ':lead_id?/application/',
                component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Workflow-Application.vue'),
            }

        ]
    },
    {
        path: '/new_business/placed_policy/',
        name: 'Placed Policies',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/PlacedPolicies.vue')
    },
    {
        path: '/new_business/conserved_polices/',
        name: 'Conserved Polices',
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/ConservedPolices.vue')
    },
    {
        path: '/new_business/applications/',
        name: 'Applications',
        // component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Application/Applications.vue')
        component: () => import( /* webpackChunkName: "workflow" */ '../views/NewBusiness/Application/ApplicationsAudit.vue')
    },
    {
        path: '/new_business/carrier_portal/',
        name: 'CarrierPortal',
        component: () => import( /* webpackChunkName: "carrier_portal" */ '../views/NewBusiness/CarrierPortal.vue')
    },
]

export default routes
