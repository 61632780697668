<template>
	<v-navigation-drawer
		v-if="!$vuetify.breakpoint.smAndDown"
		:key="toggle"
		class="q-left-drawer"
		v-model="drawer"
		:mini-variant="isMini"
		:expand-on-hover="!toggle"
		app
		clipped
		permanent
		disable-resize-watcher
		hide-overlay
		:width="drawerWidth"
		@transitionend="onTransition"
		@mouseleave.native="onMouseLeave"
	>
		<div :style="{ ...padding }" class="q-left-drawer__navigation-list-wrapper">
			<div class="q-left-drawer__navigation-list-scroller with-nav-scroll">
				<v-list-item
					v-if="canSetVisibility"
					class="navigation-list-item navigation-list-item__top-item"
					@click.stop="toggle = !toggle"
				>
					<v-list-item-content>
						<div
							class="navigation-list-item__top-item-wrapper"
							:class="isCollapsed && 'navigation-list-item__top-item-wrapper--mini'"
						>
							<PanelLeftIcon v-if="toggle" />
							<PanelRightIcon v-else />
						</div>
					</v-list-item-content>
				</v-list-item>

				<MainNavigationList
					class="q-left-drawer__navigation-list"
					:mini="isCollapsed"
				/>
			</div>
			
			<QFooterDetails class="pl-14" />
		</div>
	</v-navigation-drawer>
</template>

<script>
import MainNavigationList from './MainNavigationList.vue'
import PanelLeftIcon from './icons/PanelLeftIcon.vue'
import PanelRightIcon from './icons/PanelRightIcon.vue'
import ReleaseMeta from '@/ReleaseMeta.json'
import QFooterDetails from './QFooterDetails.vue';

const TOGGLE_LOCAL_STORAGE_KEY = 'q-sidebar-toggle'
const initialToggleValue = window.localStorage.getItem(TOGGLE_LOCAL_STORAGE_KEY)
const hasInitialToggleValue = initialToggleValue !== null
const currentYear = new Date().getFullYear();

export default {
	components: {
        MainNavigationList,
		PanelLeftIcon,
		PanelRightIcon,
		QFooterDetails,
    },
	props: {
		value: { type: Boolean, default: true },
		padding: { type: Object, default: () => ({}) },
		drawerWidth: { type: Number, default: 300 },
	},
    data () {
        return {
            toggle: true,
            isMini: false,
			defaultVisibility: true,
			isCollapsed: true,
            release: ReleaseMeta,
            currentYear,
        }
    },
	created () {
		this.toggle = this.canSetVisibility
			? hasInitialToggleValue
				? initialToggleValue === 'true'
				: this.defaultVisibility
			: this.defaultVisibility
	},
	computed: {
		drawer: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
		canSetVisibility () {
			if (this.hasRole(['SuperAdmin', 'Staff'])) { return true }
			return false
		},
	},
    watch: {
		toggle: {
			immediate: true,
			handler (newValue) {
				if (this.canSetVisibility) {
					window.localStorage.setItem(TOGGLE_LOCAL_STORAGE_KEY, newValue)
				}
				this.isMini = !newValue
				this.isCollapsed = !newValue
				this.$emit('update-toggle-status', newValue)
			},
		},
    },
    methods: {
		onTransition (evt) {
			this.isCollapsed = evt.target.clientWidth < this.drawerWidth
		},
		onMouseLeave () {
			if (!this.toggle) {
				this.isCollapsed = true
			}
		},
	},
}
</script>

<style lang="scss">
.q-left-drawer {
	.v-navigation-drawer__border {
		display: none;
	}

	&__navigation-list-wrapper {
		width: var(--app-left-drawer-width);
		background-color: var(--app-color-primary);
		height: 100vh;
		display: flex;
		flex-direction: column;
		padding-bottom: 70px;
	}

	&__navigation-list-scroller {
		flex: 1 1 auto;
	}

	// &__navigation-list {
	// 	width: calc(var(--app-left-drawer-width) - 12px);
	// }

	&:hover {
		.main-navigation-list-divider {
			width: unset;
		}

		.navigation-list-item__top-item-wrapper {
			width: 100% !important;
		}
	}

	.pl-14 {
		padding-left: 3.5rem;
	}
}
</style>
