<template>
    <v-footer v-show="showFooter" color="blue-grey" class="white--text q-footer" :class="{ 'q-footer--mobile': $vuetify.breakpoint.smAndDown }">
		<v-card style="min-width:100%;" v-if="footerSliderVisible" class="q-footer--ad">
			<v-expand-transition>
				<div class="reimagine pl-0 pr-0 ml-0 mr-0 ">
					<q-footer-slider class="q-footer-slider" :height="sliderHeight" :slug="slug"></q-footer-slider>

					<v-icon
						color="white"
						class="q-footer--close"
						style="font-size: 1.5rem !important;"
						@click="showBanner = !showBanner"
					>icon-x-close-q</v-icon>
				</div>
			</v-expand-transition>
		</v-card>

		<QFooterDetails v-if="$vuetify.breakpoint.smAndDown" class="justify-center" />
    </v-footer>
</template>

<script>
import moment from 'moment'
import QFooterSlider from "@/components/utils/QFooterSlider.vue";
import QFooterDetails from './QFooterDetails.vue';
import Page from '@/store/Models/Page'

export default {
    components: {
        QFooterSlider,
		QFooterDetails,
    },
    data() {
        return {
            showBanner: true,
			slug: 'footer-slider',
        }
    },
    computed: {
		showFooter() {
			return this.showBanner && this.slider_content.blocks?.length > 0
		},
        footerSliderVisible() {
            return this.hasPermission('division:Field')
        },
		sliderHeight() {
			return this.$vuetify.breakpoint.smAndDown ? 127 : 70
		},
        slider_content() {
            var page = Page.query().where('slug', this.slug).first();
            if (page != null) {
                page.blocks = page.blocks.filter(block => {
                    if (block.status === 'draft')
                        return false

                    if (block.startDate !== null && block.startDate !== undefined && block.startDate !== "") {
                        if (!moment(block.startDate).isSameOrBefore(moment(), 'day'))
                            return false
                    }

                    if (block.endDate !== null && block.endDate !== undefined && block.endDate !== "") {
                        if (!moment(block.endDate).isSameOrAfter(moment(), 'day'))
                            return false
                    }

                    return true
                })
                return page
            }
            return {}
        },
    },
}
</script>

<style lang="scss">
    .acsb-trigger.acsb-trigger-position-y-bottom {
        bottom: 75px !important;
    }

	.q-footer {
		height: 70px;
		padding: 0 !important;

		&--close {
			position: absolute !important;
			left: 2rem;
			bottom: 50%;
			transform: translateY(50%);
		}

		&--mobile {
			height: auto;

			.q-footer--ad {
				margin-bottom: 70px;
			}

			.q-footer--close {
				left: 1rem;
			}
		}

		.v-window__prev, .v-window__next {
			height: 24px;
			width: 24px;
			background: black;
			top: calc(50% - 12px);
			margin: 0 4px;
			
			.v-btn {
				display: flex;
				height: 24px;
				width: 24px;
			}

			.v-icon {
				height: 16px;
				width: 16px;
				font-size: 16px !important;
			}
		}
	}
</style>
